var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (me, showRequiredError, translate, view, window) {pug_mixins["recover-account"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv id=\"recover-account-wrapper\"\u003E\u003Ca id=\"link-to-recover\" data-toggle=\"coco-modal\" data-target=\"core\u002FRecoverModal\" data-i18n=\"login.forgot_password\" href=\"#\"\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
};
pug_mixins["auth-form-content"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv class=\"auth-form-content\"\u003E";
if (showRequiredError) {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-success\"\u003E\u003Cspan data-i18n=\"signup.required\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger hide\" id=\"unknown-error-alert\" data-i18n=\"loading_error.unknown\"\u003E\u003C\u002Fdiv\u003E\u003Cform class=\"form\"\u003E";
if (view.loginMessage) {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger\"\u003E\u003Cspan\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = view.loginMessage) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"form-group\"\u003E\u003Clabel class=\"control-label\" for=\"username-or-email-input\"\u003E";
if (view.showLibraryModal) {
pug_html = pug_html + "\u003Cspan data-i18n=\"login.email_or_username_or_library\"\u003E\u003C\u002Fspan\u003E";
}
else {
pug_html = pug_html + "\u003Cspan data-i18n=\"login.email_or_username\"\u003E\u003C\u002Fspan\u003E";
}
pug_html = pug_html + ":\u003C\u002Flabel\u003E";
if (me.get("preferredLanguage") === "he" || window.location.host === "il.codecombat.com") {
pug_html = pug_html + "\u003Cem class=\"israel-server-hint\" dir=\"rtl\"\u003E\u003Cspan\u003Eתלמיד מערכת החינוך?\u003C\u002Fspan\u003E\u003Ca href=\"https:\u002F\u002Fpub.skillz-edu.org\u002Fportal\u002Fplayground\"\u003E התחבר דרך פורטל משרד החינוך\u003C\u002Fa\u003E\u003C\u002Fem\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"input-border\"\u003E\u003Cinput" + (" class=\"input-large form-control\""+" id=\"username-or-email-input\" name=\"emailOrUsername\""+pug.attr("value", view.previousFormInputs.email, true, true)+" dir=\"auto\"") + "\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"form-group\"\u003E";
if (view.utils.isCodeCombat) {
pug_mixins["recover-account"]();
}
pug_html = pug_html + "\u003Clabel" + (" class=\"control-label\""+pug.attr("for", view.utils.isCodeCombat ? "password": "password-input", true, true)) + "\u003E\u003Cspan data-i18n=\"general.password\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = view.utils.isCodeCombat ? ':' : '') ? "" : pug_interp)) + "\u003C\u002Flabel\u003E\u003Cdiv class=\"input-border\"\u003E\u003Cinput" + (" class=\"input-large form-control\""+" id=\"password-input\" name=\"password\" type=\"password\""+pug.attr("value", view.previousFormInputs.password, true, true)+" dir=\"auto\"") + "\u003E\u003C\u002Fdiv\u003E";
if (view.utils.isOzaria) {
pug_mixins["recover-account"]();
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cinput" + (pug.attr("class", pug.classes(["btn","btn-block","btn-success",view.utils.isCodeCombat ? "btn-lg btn-illustrated" : ""], [false,false,false,true]), false, true)+" id=\"login-btn\""+pug.attr("value", translate(view.utils.isCodeCombat ? "login.log_in" : "login.sign_in"), true, true)+" type=\"submit\"") + "\u003E\u003C\u002Fform\u003E\u003Cdiv class=\"wait secret\"\u003E\u003Ch3 data-i18n=\"login.logging_in\"\u003E\u003C\u002Fh3\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (view.utils.isCodeCombat && me.useSocialSignOn()) {
var showClever = !/^\/play/.test(window.location.pathname);
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["auth-network-logins" + (showClever ? " three-choices" : " two-choices")], [true]), false, true)) + "\u003E\u003C!-- GitHub login complete, but the button does not fit in with the design yet. Hidden for now--\u003E\u003C!--div.network-login--\u003E\u003C!--  btn.btn.btn-sm.github-login-button#github-login-button--\u003E\u003C!--    img(src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fgithub_icon.png\")--\u003E\u003C!--    | GitHub--\u003E\u003Ca class=\"network-login signup-with-button\" id=\"facebook-login-btn\" disabled\u003E\u003Cimg class=\"fb-login-img\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Ffacebook_small.png\" draggable=\"false\"\u003E\u003Cspan\u003EFacebook\u003C\u002Fspan\u003E\u003C\u002Fa\u003E";
if (showClever) {
pug_html = pug_html + "\u003Ca class=\"network-login\" id=\"clever-signup-btn\"\u003E\u003Cimg class=\"clever-login-img\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fclever_sso_button@2x.png\" draggable=\"false\"\u003E\u003C\u002Fa\u003E";
}
pug_html = pug_html + "\u003Ca class=\"network-login\" id=\"google-login-button\" disabled\u003E\u003Cimg class=\"google-login-img\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fgplus_sso_button2.svg\" draggable=\"false\"\u003E\u003C\u002Fa\u003E\u003Ca class=\"network-login signup-with-button\" id=\"schoology-login-btn\"\u003E\u003Cimg class=\"schoology-login-img\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fschoology.png\"\u003E\u003Cspan\u003ESchoology\u003C\u002Fspan\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"extra-pane\"\u003E";
if (view.utils.isCodeCombat) {
pug_html = pug_html + "\u003Cdiv class=\"switch-explanation\" data-i18n=\"login.signup_switch\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btn btn-lg btn-illustrated btn-warning\" id=\"switch-to-signup-btn\" data-i18n=\"login.sign_up\"\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ca id=\"switch-to-signup-btn\" data-i18n=\"login.auth_sign_up\" href=\"#\"\u003E\u003C\u002Fa\u003E\u003Cp data-i18n=\"login.already_have_account1\"\u003E\u003C\u002Fp\u003E\u003Cp data-i18n=\"login.already_have_account2\"\u003E\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["modal-dialog",(view.utils.isOzaria ? "style-ozaria" : "")], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"modal-content\"\u003E";
if (view.utils.isCodeCombat) {
pug_html = pug_html + "\u003Cimg class=\"auth-modal-background\" src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Flogin-background.png\" draggable=\"false\"\u003E\u003Ch1 data-i18n=\"login.log_in\"\u003E\u003C\u002Fh1\u003E\u003Cdiv id=\"close-modal\"\u003E\u003Cspan class=\"glyphicon glyphicon-remove\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Ch2 data-i18n=\"login.sign_into_ozaria\"\u003E\u003C\u002Fh2\u003E";
if (me.useSocialSignOn()) {
pug_html = pug_html + "\u003Cdiv class=\"auth-network-logins\"\u003E\u003C!-- GitHub login complete, but the button does not fit in with the design yet. Hidden for now--\u003E\u003C!--div.network-login--\u003E\u003C!--  btn.btn.btn-sm.github-login-button#github-login-button--\u003E\u003C!--    img(src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fgithub_icon.png\")--\u003E\u003C!--    |  GitHub--\u003E\u003Ca id=\"google-login-button\" disabled href=\"#\"\u003E\u003Cimg src=\"\u002Fimages\u002Fozaria\u002Fcommon\u002Flog-in-google-sso.svg\" draggable=\"false\"\u003E\u003C\u002Fa\u003E\u003Ca id=\"clever-login-btn\" href=\"#\"\u003E\u003Cimg src=\"\u002Fimages\u002Fpages\u002Fmodal\u002Fauth\u002Fclever_sso_button@2x.png\" draggable=\"false\"\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"row or-row\"\u003E\u003Cdiv class=\"line\"\u003E\u003C\u002Fdiv\u003E\u003Cp class=\"or\" data-i18n=\"login.or\"\u003E\u003C\u002Fp\u003E\u003Cdiv class=\"line\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_mixins["auth-form-content"]();
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (view.utils.isCodeCombat) {
pug_mixins["auth-form-content"]();
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";}.call(this,"me" in locals_for_with?locals_for_with.me:typeof me!=="undefined"?me:undefined,"showRequiredError" in locals_for_with?locals_for_with.showRequiredError:typeof showRequiredError!=="undefined"?showRequiredError:undefined,"translate" in locals_for_with?locals_for_with.translate:typeof translate!=="undefined"?translate:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined,"window" in locals_for_with?locals_for_with.window:typeof window!=="undefined"?window:undefined));;return pug_html;};
module.exports = template;